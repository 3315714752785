import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { Container, Row, Col, Media, Button, CardHeader, Table, Card, CardBody, Pagination, PaginationItem, PaginationLink, } from 'reactstrap';
import { toast } from 'react-toastify';
import "./contacts.css";
import { Link, useParams } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import moment from 'moment';

export default function DetailedContact() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [contact, setContact] = useState({ contact_prescription_possibility: {}, contact_potential: {} });
  const [page, setPage] = useState(1)
  const { slug } = useParams();
  const current_contact_id = slug;
  const token = document.cookie.split(`jwt=`).pop().split(';').shift();
  const loggedInUserNetwork = jwt.decode(token).network;
 

  const fetchData = async () => {
    setLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ item_per_page: 10, page: page, contact_id: current_contact_id })
    }
    try {
      var responce = await fetch("http://localhost:1234/api/reports/all", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setData(data);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }
  const fetchContact = async () => {
    setLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({ contact_id: current_contact_id })
    }
    try {
      var responce = await fetch("https://lsreport.shls.care/api/contacts/contact", request, { mode: "cors" });
      if (responce.status === 200) {
        let data = await responce.json();
        setContact(data);
      } else {
        toast.error(await responce.json());
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
    setLoading(false);
  }
  const paginationNumber = (page) => {
    setPage(page);
  }
  const totalpages = () => {
    if (data.length > 0) {
      const total = Math.ceil(data[0].report.full_count / 10);
      return total;
    } else {
      return 0
    }
  }

  useEffect(() => {
    fetchContact();
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, [page])

  return (
    <Fragment>
      <Loader isLoading={loading} />
      <Breadcrumbs parent="Contacts" title="Detail contact" />
      <Container fluid={true} className='pb-4 mb-4'>
        <Row className='pb-4'>
          <Col xl="6" className='mb-4'>
            <Card>
              <CardHeader>
                <Row className="mb-2">
                  <div className="col-auto">
                    <Media className="img-70 rounded-circle" alt="" src={require("../../assets/images/user/7.jpg")} />
                  </div>
                  <Col>
                    <h5 className="mb-1">{contact.contact_lastname} {contact.contact_firstname}</h5>
                    <p className="mb-4">{contact.contact_speciality}</p>
                    <p></p>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody  className='pb-4'>
                <Row className="mb-2 pb-4">


                  <Col>
                    <Table>
                      <tbody>
                      <tr>
                          <th>Code:</th>
                          <td>{contact.contact_id}</td>
                        </tr>
                        <tr>
                          <th>Adress:</th>
                          <td>{contact.contact_address}, {contact.contact_city} ,{contact.contact_wilaya}</td>
                        </tr>
                        <tr>
                          <th>Spécialité:</th>
                          <td>{contact.contact_speciality}</td>
                        </tr>
                        <tr>
                          <th>Potentiel:</th>
                          <td>{contact.contact_potential[loggedInUserNetwork] ?? contact.contact_potential["Visite Pharma"]}</td>
                        </tr>
                        <tr>
                          <th>Secteur:</th>
                          <td>{contact.contact_sector}</td>
                        </tr>
                        <tr>
                          <th>Sexe:</th>
                          <td>{contact.contact_sexe}</td>
                        </tr>
                        {contact.contact_speciality === "Pharmacien" ?
                          <>
                          <tr>
                          <th>Surface pharmacie:</th>
                          <td>{contact.pharmacie_area}</td>
                        </tr>
                          </>
                          :
                          <>
                            <tr>
                              <th>KOL:</th>
                              <td>{contact.contact_kol ? "Oui" : "Non"}</td>
                            </tr>
                            <tr>
                              <th>Inéret pour notre produit / marque:</th>
                              <td>{contact.contact_products_intrest}</td>
                            </tr>
                            <tr>
                              <th>Princeps contre génerique:</th>
                              <td>{contact.generic_princeps}</td>
                            </tr>
                            <tr>
                              <th>SONCAS:</th>
                              <td>{contact.contact_soncas}</td>
                            </tr>
                            <tr>
                              <th>Délai d'attente:</th>
                              <td>{contact.contact_waiting_duration}</td>
                            </tr>
                            <tr>
                              <th>Informatique:</th>
                              <td>{contact.application}</td>
                            </tr>
                            <tr>
                              <th>Possibilité de prescription:</th>
                              <td>{contact.contact_prescription_possibility[loggedInUserNetwork]}</td>
                            </tr>
                          </>
                        }
                        <tr>
                          <th>Patients par jour:</th>
                          <td>{contact.contact_nbr_patient}</td>
                        </tr>
                        <tr>
                          <th>Email:</th>
                          <td>{contact.contact_email}</td>
                        </tr>
                        <tr>
                          <th>Téléphone:</th>
                          <td>{contact.contact_phone}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={12} className='justify-content-center  mb-4' style={{ display: "flex" }}>
                    <Link to={{
                      pathname: `${process.env.PUBLIC_URL}/contacts/modify:${contact.contact_id}`,
                      state: {
                        contact: contact
                      }
                    }}>
                      <Button>Modifier</Button>
                    </Link>
                  </Col>
                  {/* <Col md={12} className='justify-content-center m-2' style={{ display: "flex" }}>
                    <Button>Supprimer</Button>
                  </Col> */}
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xl="6" className='mb-4'>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">Rapports</h4>
              </CardHeader>
              {data.length === 0 ?
                <CardBody>
                  <h5>Aucun rapport à afficher</h5>
                </CardBody>
                :
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date de visite</th>
                        <th>Visiteur</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item) => {
                        return (
                          <tr key={data.indexOf(item)}>
                            <td> {(data.indexOf(item) + 1) + ((page - 1) * 10)}</td>
                            <td>{moment(item.report.report_done_on).format("DD/MM/yyyy à HH:mm")}</td>
                            <td>
                              <Link to={`${process.env.PUBLIC_URL}/users/user:${item.agent.user_id}`}>
                                {item.agent.user_lastname + " " + item.agent.user_firstname}
                              </Link>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Link to={{
                                pathname: `${process.env.PUBLIC_URL}/visites/rapports/detailed`,
                                state: {
                                  reportID: item.report.report_id,
                                  contact_lastname: contact.contact_lastname,
                                  contact_firstname: contact.contact_firstname,
                                  contact_speciality: contact.contact_speciality,
                                  contact_address: contact.contact_address,
                                  contact_wilaya: contact.contact_wilaya,
                                  contact_prescription_possibility: contact.contact_prescription_possibility == null ? "" : contact.contact_prescription_possibility[item.agent.user_network],
                                  contact_potentiel: (contact.contact_potential[item.agent.user_network] ?? contact.contact_potential["Visite Pharma"]),
                                  agent_lastname: item.agent.user_lastname,
                                  agent_firstname: item.agent.user_firstname,
                                  agent_network: item.agent.user_network,
                                  contact_id: contact.contact_id,
                                  agent_id: item.agent.user_id
                                }
                              }}>
                                <i className="fa fa-table" id="TooltipExample"></i>
                              </Link>
                            </td>
                          </tr>
                        )
                      })}

                    </tbody>
                  </Table>
                </CardBody>
              }
            </Card>
            <Col className='mb-4'>
              <Pagination aria-label="Page navigation example" className="pagination justify-content-center pagination-primary mb-5">
                <PaginationItem><PaginationLink onClick={() => paginationNumber(page > 1 ? page - 1 : 1)}><span aria-hidden="true">«</span><span className="sr-only">Previous</span></PaginationLink></PaginationItem>
                {(totalpages() === 1) &&
                  <PaginationItem active={true}><PaginationLink >{page}</PaginationLink></PaginationItem>
                }
                {(totalpages() === 2) &&
                  <>
                    <PaginationItem active={page === 1 ? true : false}><PaginationLink onClick={() => paginationNumber(page === 1 ? page : page - 1)}>{page === 1 ? page : page - 1}</PaginationLink></PaginationItem>
                    <PaginationItem active={page === 2 ? true : false}><PaginationLink onClick={() => paginationNumber(page === 2 ? page : page + 1)}>{page === 2 ? page : page + 1}</PaginationLink></PaginationItem>
                  </>
                }
                {(totalpages() > 2) &&
                  <>
                    <PaginationItem active={page === 1 ? true : false}><PaginationLink onClick={() => paginationNumber(page > 1 ? page - 1 : page)}>{page === totalpages() ? page - 2 : page > 1 ? page - 1 : page}</PaginationLink></PaginationItem>
                    <PaginationItem active={page != 1 && page != totalpages() ? true : false}><PaginationLink onClick={() => paginationNumber(page === totalpages() ? page - 1 : page > 1 ? page : page + 1)}>{page === totalpages() ? page - 1 : page > 1 ? page : page + 1}</PaginationLink></PaginationItem>
                    <PaginationItem active={page === totalpages() ? true : false}><PaginationLink onClick={() => paginationNumber(page === totalpages() ? page : page > 1 ? page + 1 : page + 2)}>{page === totalpages() ? page : page > 1 ? page + 1 : page + 2}</PaginationLink></PaginationItem>
                  </>
                }
                <PaginationItem><PaginationLink onClick={() => paginationNumber((totalpages() <= page) ? page : page + 1)}><span aria-hidden="true">»</span><span className="sr-only">Next</span></PaginationLink></PaginationItem>
              </Pagination>
            </Col>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
